import React from 'react';
import PropTypes from 'prop-types';

// import Modal from 'react-modal';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import logo from '../images/cheeky-logo.png';
import twitter from '../images/twitter.gif';

import './mobileMenu.scss';

const MobileMenu = ({ menuOpen, setMenuOpen }) => (
  <Modal open={menuOpen} onClose={() => setMenuOpen(false)}>
    <div className="mobile-menu">
      <div className="mobile-menu__header">
        <h1>Chains on Dogs</h1>
        <button
          type="button"
          onClick={() => setMenuOpen(false)}
          className="mobile-menu__close"
        >
          <CloseIcon fontSize="large" style={{ color: 'white' }} />
        </button>
      </div>
      <div className="mobile-menu__content">
        <div className="mobile-menu__text-links">
          <a className="mobile-menu__text-link" href="/#stats">
            Doggo Stats
          </a>
          <a className="mobile-menu__text-link" href="/#gamejam">
            GameJam
          </a>
        </div>
        <div className="mobile-menu__socials">
          <a
            className="mobile-menu__social-link"
            href="https://twitter.com/chainsondogs"
          >
            <img src={twitter} alt="Twitter" />
          </a>
        </div>
        <a className="mobile__adopt-link" href="#">
          Adopt An Doggo
        </a>
      </div>
    </div>
  </Modal>
);

MobileMenu.defaultProps = {
  menuOpen: false,
};

MobileMenu.propTypes = {
  menuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func.isRequired,
};

export default MobileMenu;
